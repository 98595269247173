import { tsParticles } from 'tsparticles';

const _si = (selector) => {
	const elm = document.querySelector(selector);
	return elm || document.createElement('div');
};

const _s = (selector) => {
	return Array.apply([], document.querySelectorAll(selector));
};

const main = (() => {
	_si('.css--menu-toggle').addEventListener('click', () => {
		document.body.classList.toggle('css--show-menu');
	});

	_s('.js--click').forEach((elm) => {
		elm.addEventListener('click', () => {
			const a = elm.querySelector('a');
			if (a) {
				a.click();
			}
		});
	});

	_si('.js--cat-nav').addEventListener('click', (e) => {
		const id = e.target.getAttribute('data-cat');
		if (id) {
			_s('.active[data-cat]').forEach((elm) => {
				elm.classList.remove('active');
			});
			e.target.classList.add('active');
			_s('.js--article').forEach((elm) => {
				console.log(elm.getAttribute('data-cat'), id);
				if (id === 'all' || elm.getAttribute('data-cat') === id) {
					elm.classList.remove('hidden');
				} else {
					elm.classList.add('hidden');
				}
			});
		}
	});

	_s('.js--fact-toggle').forEach((elm) => {
		elm.addEventListener('click', () => {
			elm.previousElementSibling.classList.toggle('max-h-240');
			elm.firstElementChild.classList.toggle('rotate-45');
		});
	});

	_s('.js--image-fullscreen').forEach((elm) => {
		elm.addEventListener('click', () => {
			elm.nextElementSibling.classList.remove('hidden');
			elm.nextElementSibling.classList.add('flex');
		});
	});

	_s('.js--image-close-fullscreen').forEach((elm) => {
		hideModal(elm);
	});

	_s('.js--backdrop').forEach((elm) => {
		hideModal(elm);
	});

	function hideModal(elm) {
		elm.addEventListener('click', (e) => {
			const tag = e.target.tagName.toLowerCase();
			if (tag === 'img' || tag === 'span') {
				return;
			}
			const modal = elm.closest('.js--backdrop');
			modal.classList.add('hidden');
			modal.classList.remove('flex');
		});
	}

	if (document.getElementById('particles-js')) {
		tsParticles.loadJSON('particles-js', '/dist/json/particles.json');
	}

	setTimeout(() => {
		document
			.querySelector('main')
			.classList.add('transition', 'duration-500');
		document.querySelector('main').classList.remove('opacity-0');
	}, 200);
})();
